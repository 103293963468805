import React from "react";
import shortid from  "shortid";
const TechnehireSection2 = (props) => {
    return(
        <section className="section-2">
            <div className="container">
                <div className="block-1">
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-18">
                            <div className="title-block">
                                <h1 className="h1 section-title normal-spacing">{props.mainTitle}</h1>
                                <p className="section-description">{props.mainDescription}</p>
                            </div>
                            <div className="image-block">
                                <img
                                    src={props.image1x?.sourceUrl}
                                    srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                                    width={props.image1x?.width}
                                    alt={props.image1x?.altText}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                </div>
                <div className="block-2">
                    <div className="row">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-22">
                            <div className="image-block">
                                <div className="row">
                                        {props.imageslist?.map((object, i) => {
                                            return (
                                                <div className="col-lg-12" key={shortid.generate()}>
                                                    <div className="item">
                                                        <img
                                                            src={object.image1x?.sourceUrl}
                                                            srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                                            width={object.image1x?.width}
                                                            alt={object.image1x?.altText}
                                                        />
                                                    </div>
                                                </div>     
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        <div className="col-lg-1"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TechnehireSection2;